import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import "./scss/index.scss";

var Button = function Button(_ref) {
  var _ref$className = _ref.className,
      className = _ref$className === void 0 ? "" : _ref$className,
      children = _ref.children,
      testingContext = _ref.testingContext,
      secondary = _ref.secondary,
      third = _ref.third,
      btnRef = _ref.btnRef,
      type = _ref.type,
      otherProps = _objectWithoutProperties(_ref, ["className", "children", "testingContext", "secondary", "third", "btnRef", "type"]);

  return React.createElement("button", _extends({
    "data-test": testingContext,
    className: "button ".concat(third ? "third" : secondary ? "secondary" : "", " ").concat(className),
    ref: btnRef,
    type: type
  }, otherProps), React.createElement("span", null, children));
};

export default Button;