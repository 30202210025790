import * as React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";
import { NavLink, SocialMediaIcon } from "..";
import { TypedSecondaryMenuQuery } from "./queries";
import { SOCIAL_MEDIA } from "../../core/config";
import arrowRightImg from "../../images/arrow-right.svg";
import "./scss/index.scss";

var Nav = function Nav() {
  return React.createElement("footer", {
    className: "footer-nav"
  }, React.createElement("div", {
    className: "footer-nav-left"
  }, React.createElement("h4", {
    className: "footer-nav__section-header"
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.1037507793",
    defaultMessage: "Stay In Touch"
  })), React.createElement("div", {
    className: "footer-nav__section-content"
  }, React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.1121729365",
    defaultMessage: "{receiveEmail}",
    values: {
      receiveEmail: React.createElement("p", null, "Receive email updates on products, launches and events.")
    }
  }), React.createElement(FormattedMessage, {
    id: "components.Footer.Nav.4171937951",
    defaultMessage: "{anytime}",
    values: {
      anytime: React.createElement("p", null, "Unsubscribe anytime.")
    }
  })), React.createElement("div", {
    className: "footer-nav-input"
  }, React.createElement("div", {
    className: "input-container"
  }, React.createElement("input", {
    placeholder: "Your email"
  }), React.createElement(ReactSVG, {
    path: arrowRightImg,
    className: "arrow-right"
  }))), React.createElement("div", {
    className: "footer__favicons"
  }, SOCIAL_MEDIA.map(function (medium) {
    return React.createElement(SocialMediaIcon, {
      medium: medium,
      key: medium.ariaLabel
    });
  }))), React.createElement("div", {
    className: "footer-nav-right"
  }, React.createElement(TypedSecondaryMenuQuery, null, function (_ref) {
    var data = _ref.data;
    return data.shop.navigation.secondary.items.map(function (item) {
      return React.createElement("div", {
        className: "footer-nav__section",
        key: item.id
      }, React.createElement("h4", {
        className: "footer-nav__section-header"
      }, React.createElement("span", null, item.name)), React.createElement("div", {
        className: "footer-nav__section-content"
      }, item.children.map(function (subItem) {
        return React.createElement("p", {
          key: subItem.id
        }, React.createElement(NavLink, {
          item: subItem
        }));
      })));
    });
  })));
};

export default Nav;