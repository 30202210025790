/* eslint-disable global-require */
import { generatePageUrl } from "./utils";
export var BASE_URL = "/";
export var PRODUCTS_PER_PAGE = 6;
export var SUPPORT_EMAIL = "support@example.com";
export var PROVIDERS = {
  BRAINTREE: {
    label: "Braintree"
  },
  DUMMY: {
    label: "Dummy"
  },
  STRIPE: {
    label: "Stripe"
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity: "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous"
    },
    style: {
      src: "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity: "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous"
    }
  },
  // new line
  PAYPAL: {
    label: "Paypal"
  },
  CreditCard: {
    label: "Credit-Card"
  },
  UNIONPAY: {
    label: "UnionPay"
  },
  ALIPAY: {
    label: "AliPay"
  },
  WECHATPAY: {
    label: "WeToPay"
  }
};
export var STATIC_PAGES = [{
  label: "About",
  url: generatePageUrl("about")
}];
export var SOCIAL_MEDIA = [{
  ariaLabel: "facebook",
  href: "https://www.facebook.com/mirumeelabs/",
  path: require("../images/facebook-icon.svg")
}, {
  ariaLabel: "instagram",
  href: "https://www.instagram.com/mirumeelabs/",
  path: require("../images/instagram-icon.svg")
}, // {
//   ariaLabel: "twitter",
//   href: "https://twitter.com/getsaleor",
//   path: require("../images/twitter-icon.svg"),
// },
{
  ariaLabel: "pintrest",
  href: "https://www.pinterest.com/",
  path: require("../images/pintrest.svg")
}, {
  ariaLabel: "tiktok",
  href: "https://www.douyin.com/",
  path: require("../images/tiktok.svg")
}, {
  ariaLabel: "youtube",
  href: "https://www.youtube.com/channel/UCg_ptb-U75e7BprLCGS4s1g/videos",
  path: require("../images/youtube-icon.svg")
}];
export var META_DEFAULTS = {
  custom: [],
  description: "Lachiver, 100% Pure New Zealand Product for Men",
  image: "".concat(window.location.origin).concat(require("../images/logo.svg")),
  title: "L'achiever",
  type: "website",
  url: window.location.origin
};
export var CheckoutStep;

(function (CheckoutStep) {
  CheckoutStep[CheckoutStep["Address"] = 1] = "Address";
  CheckoutStep[CheckoutStep["Shipping"] = 2] = "Shipping";
  CheckoutStep[CheckoutStep["Payment"] = 3] = "Payment";
  CheckoutStep[CheckoutStep["Review"] = 4] = "Review";
  CheckoutStep[CheckoutStep["PaymentConfirm"] = 5] = "PaymentConfirm";
})(CheckoutStep || (CheckoutStep = {}));

export var CHECKOUT_STEPS = [{
  index: 0,
  // link: "/checkout/address",
  link: "/checkout/checkout",
  name: "Address",
  // nextActionName: "Continue to Shipping",
  nextActionName: "Pay Securely",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Address
}, {
  index: 1,
  // link: "/checkout/shipping",
  link: "/shipping",
  name: "Shipping",
  // nextActionName: "Continue to Payment",
  nextActionName: "Pay Securely",
  onlyIfShippingRequired: true,
  step: CheckoutStep.Shipping
}, {
  index: 2,
  // link: "/checkout/payment",
  link: "/checkout/checkout",
  name: "Payment",
  nextActionName: "Pay Securely",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Payment
}, {
  index: 3,
  // link: "/checkout/review",
  link: "/checkout/checkout",
  name: "Review",
  nextActionName: "Place order",
  onlyIfShippingRequired: false,
  step: CheckoutStep.Review
}, {
  index: 4,
  // link: "/checkout/payment-confirm",
  link: "/payment-confirm",
  name: "Payment confirm",
  onlyIfShippingRequired: false,
  step: CheckoutStep.PaymentConfirm,
  withoutOwnView: true
}];