import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 1rem 0 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  // font-size: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ErrorMessage = styled.div(_templateObject(), function (props) {
  return props.theme.colors.error;
}, function (props) {
  return props.theme.input.labelFontSize;
}); // export const ErrorParagraph = styled.p`

export var ErrorParagraph = styled.span(_templateObject2());
ErrorMessage.displayName = "S.ErrorMessage";