import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss"; // import classNames from "classnames";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { HomeVideo, Readmore, UsersReview } from "@components/organisms";
import { Button, Loader, ProductsFeatured } from "../../components";
import { generateCategoryUrl } from "../../core/utils";
import { structuredData } from "../../core/SEO/Homepage/structuredData"; // import noPhotoImg from "../../images/no-photo.svg";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;

  var categoriesExist = function categoriesExist() {
    return categories && categories.edges && categories.edges.length > 0;
  };

  var intl = useIntl(); // const [language, setLanguage] = React.useState<any>("en");
  // React.useEffect(() => {
  //   const localValue = navigator.language.split("-")[0];
  //   if (localValue === "zh") {
  //     setLanguage("zh");
  //   }
  // }, []);

  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement("div", {
    className: "home-page__hero",
    style: backgroundImage ? {
      backgroundImage: "url(".concat(backgroundImage.url, ")")
    } : null
  }, React.createElement("div", {
    className: "home-page__hero-container"
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "MP_SUNSCREEN",
    defaultMessage: "MULTI-PROTECTIVE SUNSCREEN"
  })))), React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title sub-custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "UV_protector",
    defaultMessage: "UV protector - Defense harmful rays and blue screen light, available use on a variety of outdoor sports."
  }))))), React.createElement("div", {
    className: "home-page__hero-action"
  }, loading && !categories ? React.createElement(Loader, null) : categoriesExist() && React.createElement(Link, {
    to: generateCategoryUrl(categories.edges[0].node.id, categories.edges[0].node.name)
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3069060397",
    defaultMessage: "Shop"
  })))))), React.createElement(ProductsFeatured, {
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "SKINCARE FOR MEN",
      "id": "views.Home.Page.731143030"
    }, "id", "views.Home.Page.731143030"))
  }), React.createElement(HomeVideo, null), React.createElement(Readmore, null), React.createElement(UsersReview, null));
};

export default Page;