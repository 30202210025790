import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
import { // Breadcrumbs,
// extractBreadcrumbs,
ProductsFeatured } from "../../components";
import { ProductListHeader } from "../../@next/components/molecules";
import { ProductList } from "../../@next/components/organisms"; // import { FilterSidebar } from "../../@next/components/organisms/FilterSidebar";

import { maybe } from "../../core/utils";

var Page = function Page(_ref) {
  var _category$ancestors, _category$name, _category$ancestors2;

  var allProducts = _ref.allProducts,
      activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      title = _ref.title,
      discription = _ref.discription,
      category = _ref.category,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      clearFilters = _ref.clearFilters,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount; // const [showFilters, setShowFilters] = React.useState(false);

  var intl = useIntl();

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  return React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "container"
  }, ((_category$ancestors = category.ancestors) === null || _category$ancestors === void 0 ? void 0 : _category$ancestors.edges.length) ? React.createElement(S.DescriptionWrapper, null, React.createElement(S.DescriptionTitle, null, title.toLocaleUpperCase() || (category === null || category === void 0 ? void 0 : category.name.toLocaleUpperCase())), React.createElement(S.DescriptionContent, null, discription || "There is currently no description of ".concat(title.toLocaleUpperCase() || (category === null || category === void 0 ? void 0 : (_category$name = category.name) === null || _category$name === void 0 ? void 0 : _category$name.toLocaleUpperCase()), "."))) : null, React.createElement(ProductListHeader, {
    leftTitle: ((_category$ancestors2 = category.ancestors) === null || _category$ancestors2 === void 0 ? void 0 : _category$ancestors2.edges.length) ? "".concat(products.totalCount, " Product") : null,
    activeSortOption: activeSortOption // openFiltersMenu={() => setShowFilters(true)}
    ,
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), canDisplayProducts && React.createElement("div", {
    className: "product-list"
  }, React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  }))), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;