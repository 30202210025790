import React from "react"; // import { FormattedMessage } from "react-intl";

import { Chip, DropdownSelect } from "@components/atoms";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl"; // import { commonMessages } from "@temp/intl";

import * as S from "./styles";
export var ProductListHeader = function ProductListHeader(_ref) {
  var _ref$numberOfProducts = _ref.numberOfProducts,
      numberOfProducts = _ref$numberOfProducts === void 0 ? 0 : _ref$numberOfProducts,
      openFiltersMenu = _ref.openFiltersMenu,
      clearFilters = _ref.clearFilters,
      activeSortOption = _ref.activeSortOption,
      _ref$activeFilters = _ref.activeFilters,
      activeFilters = _ref$activeFilters === void 0 ? 0 : _ref$activeFilters,
      _ref$activeFiltersAtt = _ref.activeFiltersAttributes,
      activeFiltersAttributes = _ref$activeFiltersAtt === void 0 ? [] : _ref$activeFiltersAtt,
      _ref$sortOptions = _ref.sortOptions,
      sortOptions = _ref$sortOptions === void 0 ? [] : _ref$sortOptions,
      onChange = _ref.onChange,
      onCloseFilterAttribute = _ref.onCloseFilterAttribute,
      leftTitle = _ref.leftTitle;
  var intl = useIntl();
  return React.createElement(S.Wrapper, null, React.createElement(S.Bar, null, React.createElement(S.LeftSide, null, React.createElement("h2", null, (leftTitle === null || leftTitle === void 0 ? void 0 : leftTitle.toUpperCase()) || intl.formatMessage(commonMessages.allproduct).toString().toUpperCase())), React.createElement(S.RightSide, null, React.createElement(S.Element, null, React.createElement(S.Sort, null, React.createElement(DropdownSelect, {
    onChange: onChange,
    options: sortOptions,
    value: sortOptions.find(function (option) {
      return option.value === activeSortOption;
    })
  }))))), React.createElement(S.FiltersChipsWrapper, null, activeFiltersAttributes.map(function (_ref2) {
    var attributeSlug = _ref2.attributeSlug,
        valueName = _ref2.valueName,
        valueSlug = _ref2.valueSlug;
    return React.createElement(Chip, {
      onClose: function onClose() {
        return onCloseFilterAttribute(attributeSlug, valueSlug);
      }
    }, valueName);
  })));
};