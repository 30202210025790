import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";

/**
 * Radio input.
 */
var Radio = function Radio(_ref) {
  var checked = _ref.checked,
      value = _ref.value,
      children = _ref.children,
      defaultChecked = _ref.defaultChecked,
      _ref$customLabel = _ref.customLabel,
      customLabel = _ref$customLabel === void 0 ? false : _ref$customLabel,
      props = _objectWithoutProperties(_ref, ["checked", "value", "children", "defaultChecked", "customLabel"]);

  var StyledInput = customLabel ? S.Input : S.LabeledInput;
  return React.createElement(StyledInput, {
    checked: checked || false
  }, React.createElement("input", _extends({
    type: "radio",
    value: value,
    defaultChecked: defaultChecked
  }, props, {
    onChange: function onChange() {
      return undefined;
    }
  })), " ", React.createElement("i", {
    className: "custom_definition"
  }), children);
};

export { Radio };