import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 60px;\n  margin-top: 60px;\n  padding: 0 10px;\n  width: 100%;\n  height: 400px;\n  display: grid;\n  grid-template-columns: 40% 60%;\n  .left-section {\n    width: 100%;\n    min-height: 400px;\n    overflow: hidden;\n    background-image: url(", ");\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n  .right-section {\n    align-self: center;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import usersreview from "images/usersreview.png";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), usersreview);
export var OuterWrapper = styled.div(_templateObject2());