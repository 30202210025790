import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { generatePath, Link } from "react-router-dom"; // import ReactSelect from "react-select";

import { Loader } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
import { cartUrl } from "@temp/app/routes";
import { Container } from "../Container";
import * as S from "./styles";
import { CheckoutPayPalButton } from "./PayPalButton";
/**
 * Template for checkout page.
 */

var Checkout = function Checkout(_ref) {
  var user = _ref.user,
      loading = _ref.loading,
      navigation = _ref.navigation,
      checkout = _ref.checkout,
      paymentGateways = _ref.paymentGateways,
      _ref$hidePaymentGatew = _ref.hidePaymentGateways,
      hidePaymentGateways = _ref$hidePaymentGatew === void 0 ? false : _ref$hidePaymentGatew,
      checkoutSummary = _ref.checkoutSummary,
      button = _ref.button,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      processPayment = _ref.processPayment,
      items = _ref.items,
      totalPrice = _ref.totalPrice,
      shippingPrice = _ref.shippingPrice,
      subtotalPrice = _ref.subtotalPrice,
      discount = _ref.discount,
      setSubmitInProgress = _ref.setSubmitInProgress,
      availablePaymentGateways = _ref.availablePaymentGateways;
  var selectIndex = (selectedPaymentGateway === null || selectedPaymentGateway === void 0 ? void 0 : selectedPaymentGateway.lastIndexOf(".")) || -1;
  var paymentway = (selectedPaymentGateway === null || selectedPaymentGateway === void 0 ? void 0 : selectedPaymentGateway.substring(selectIndex + 1)) || "";
  var userCopy = user;
  return React.createElement(Container, null, loading && React.createElement(S.Loader, null, React.createElement(Loader, {
    fullScreen: true
  })), React.createElement(S.Wrapper, null, React.createElement(S.SecureTitle, null, React.createElement("h1", null, React.createElement(FormattedMessage, checkoutMessages.secureCheckout))), React.createElement(S.UserInfo, null, React.createElement("h2", {
    className: "welcome"
  }, React.createElement(FormattedMessage, _extends({}, checkoutMessages.hey, {
    values: {
      firstName: userCopy === null || userCopy === void 0 ? void 0 : userCopy.firstName,
      lastName: userCopy === null || userCopy === void 0 ? void 0 : userCopy.lastName
    }
  }))), React.createElement("p", {
    className: "loginType"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.templates.Checkout.Checkout.1789268371",
    defaultMessage: "You are signed in as {email}",
    values: {
      email: React.createElement("span", null, userCopy === null || userCopy === void 0 ? void 0 : userCopy.email)
    }
  }))), React.createElement(S.Checkout, null, checkout), React.createElement(S.PaymentGateways, {
    hide: hidePaymentGateways
  }, paymentGateways), React.createElement(S.CheckoutSummary, null, checkoutSummary), React.createElement(S.ButtonWrapper, null, paymentway.toUpperCase() !== "PAYPAL" ? React.createElement(S.Button, null, button) : React.createElement(CheckoutPayPalButton, {
    processPayment: processPayment,
    items: items,
    totalPrice: totalPrice,
    shippingPrice: shippingPrice,
    subtotalPrice: subtotalPrice,
    discount: discount,
    setSubmitInProgress: setSubmitInProgress,
    user: userCopy,
    selectedPaymentGateway: selectedPaymentGateway,
    paymentGateways: availablePaymentGateways
  }), React.createElement(Link, {
    to: generatePath(cartUrl, {
      token: undefined
    })
  }, React.createElement(FormattedMessage, {
    id: "@next.components.templates.Checkout.Checkout.2044918650",
    defaultMessage: "Return to cart"
  })))));
};

export { Checkout };