import React from "react";
import { Container } from "@components/templates";
import { Button } from "src/components";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
export var Readmore = function Readmore() {
  return React.createElement(S.OuterWrapper, null, React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "readmore-container"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "REDEFINE MEN SKINIMALISM"), React.createElement("p", null, "Minimalist the number of product used, and minimalist the product ingredients.l'achiever committed to the of \"harmless\" product concepts, including safety, safety, sustainability and affordability. (Button link to this part under brand story)")), React.createElement("div", {
    className: "right-section"
  }, React.createElement(Button, {
    testingContext: "readMore",
    secondary: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Readmore.Readmore.1341826899",
    defaultMessage: "Read More"
  })))))), React.createElement(S.ImageWrapper, null, React.createElement("div", {
    className: "static-img"
  }, React.createElement("div", {
    className: "icon-wrapper icon-wrapper-1"
  }, React.createElement("div", {
    className: "icon-img icon-img-1"
  }), React.createElement("p", {
    className: "icon-text"
  }, "New Zealand\u2019s Purely Natural Ingredients")), React.createElement("div", {
    className: "icon-wrapper icon-wrapper-2"
  }, React.createElement("div", {
    className: "icon-img icon-img-2"
  }), React.createElement("p", {
    className: "icon-text"
  }, "Minimal and Effective Natural Formula")), React.createElement("div", {
    className: "icon-wrapper icon-wrapper-3"
  }, React.createElement("div", {
    className: "icon-img icon-img-3"
  }), React.createElement("p", {
    className: "icon-text"
  }, "Advanced Biotechnology & Nanotechnology")), React.createElement("div", {
    className: "icon-wrapper icon-wrapper-4"
  }, React.createElement("div", {
    className: "icon-img icon-img-4"
  }), React.createElement("p", {
    className: "icon-text"
  }, "Commited to Eco Conception")))));
};
Readmore.displayName = "Readmore";
export default Readmore;