import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  width: calc(100% - 2.26rem);\n  margin-left: 2.26rem;\n  padding-bottom: 2rem;\n  padding-top: 1rem;\n  textarea {\n    padding: 20px;\n    width: 100%;\n    height: 100px;\n    border: 1px solid ", ";\n    transition: all 0.3s ease;\n    color: ", "\n    &:focus-visible {\n      outline: none;\n    }\n    &:hover {\n      outline-width: 1px;\n      outline-style: solid;\n      border-color: ", ";\n      outline-color: ", ";\n    }\n    &::placeholder {\n      color: ", ";\n    }\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  line-height: 3rem;\n  display: flex;\n  &:first-child {\n    margin-top: 1rem;\n  }\n  .payment_name {\n    width: 10rem;\n  }\n  .svg {\n    margin-left: 12px;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 3rem 0 0.5rem 0;\n  border-bottom: 2px solid\n    ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 350px;\n  height: 50px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 10px 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 350px;\n  margin: 10px 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  width: 100%;\n  .fix-width {\n    width: 350px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  .title {\n    line-height: 40px;\n  }\n  div.address_selector {\n    display: flex;\n  }\n  div.address_selector div:first-child {\n    width: 100%;\n  }\n  .create-new {\n    margin: 0 4px;\n    cursor: pointer;\n\n    svg * {\n      transition: 0.3s;\n    }\n\n    &:hover {\n      svg * {\n        fill: ", ";\n      }\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Title = styled.h3(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var AddressSelect = styled.form(_templateObject3(), function (props) {
  return props.theme.colors.primary;
});
export var CreateNewAddress = styled.form(_templateObject4());
export var ShortInput = styled.div(_templateObject5());
export var LongInput = styled.div(_templateObject6());
export var ContrySelect = styled.div(_templateObject7());
export var DeliverWrapper = styled.div(_templateObject8());
export var ShippingMethodWrapper = styled.form(_templateObject9());
export var BorderLineTitle = styled.h3(_templateObject10(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var RadioWrapper = styled.div(_templateObject11());
export var DeliveryTextArea = styled.div(_templateObject12(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, function (props) {
  return props.theme.colors.inputColor;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, function (props) {
  return props.theme.colors.borderLine;
}); // export const PaymentFooterTitle = styled.h3`
//   font-size: 16px;
//   font-weight: 700;
//   color: #426d61;
//   line-height: 3rem;
// `;