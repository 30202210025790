import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  top: 100%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 1rem;\n  transition-duration: 0.3s;\n  transform: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  // margin-bottom: ", ";\n  .css-1szy77t-control {\n    box-shadow: 0 0 0 1px ", ";\n    &:hover,\n    &:active,\n    &:focus,\n    &:visited,\n    &:focus-within {\n      border: none;\n    }\n  }\n  .css-dvua67-singleValue {\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.inputColor;
});
export var Indicator = styled.div(_templateObject2(), function (props) {
  return props.rotate === "true" ? "rotate(180deg)" : "rotate(0deg)";
});
export var HelpText = styled.span(_templateObject3(), function (props) {
  return props.theme.input.labelColor;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject4());