import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .static-img {\n    width: 100%;\n    min-height: 400px;\n    overflow: hidden;\n    background-image: url(", ");\n    background-size: cover;\n    background-repeat: no-repeat;\n    display: grid;\n    grid-template-areas: \"icon_1 icon_2 icon_3 icon_4\";\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    padding: 4rem 6rem;\n    grid-column-gap: 4rem;\n    div.icon-wrapper {\n      &-1 {\n        grid-area: icon_1;\n      }\n      &-2 {\n        grid-area: icon_2;\n      }\n      &-3 {\n        grid-area: icon_3;\n      }\n      &-4 {\n        grid-area: icon_4;\n      }\n    }\n    div.icon-img {\n      min-height: 190px;\n      &-1 {\n        background: url(", ") center/70% no-repeat;\n      }\n      &-2 {\n        background: url(", ") center/70% no-repeat;\n      }\n      &-3 {\n        background: url(", ") center/70% no-repeat;\n      }\n      &-4 {\n        background: url(", ") center/70% no-repeat;\n      }\n    }\n    p.icon-text {\n      color: ", ";\n      text-transform: ", ";\n      text-align: center;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 60px;\n  margin-top: 60px;\n  .readmore-container {\n    display: grid;\n    grid-template-columns: 65% 35%;\n  }\n  .left-section,\n  .right-section {\n    h2 {\n      font-size: 2rem;\n      font-weight: 900;\n    }\n    p {\n      margin-top: 20px;\n      font-size: 16px;\n      font-weight: 500;\n      text-align: justify;\n    }\n  }\n  .right-section {\n    padding-left: 80px;\n    align-self: center;\n    button.button {\n      transform: none;\n      width: 100%;\n      box-shadow: none;\n      span {\n        transform: none;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import readmoreBg from "images/home-icon-wrapper-bg.png";
import homeIcon1 from "images/home-icon-1.png";
import homeIcon2 from "images/home-icon-2.png";
import homeIcon3 from "images/home-icon-3.png";
import homeIcon4 from "images/home-icon-4.png";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var ImageWrapper = styled.div(_templateObject2(), readmoreBg, homeIcon1, homeIcon2, homeIcon3, homeIcon4, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.message.titleTransform;
});
export var OuterWrapper = styled.div(_templateObject3());