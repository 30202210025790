import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  // ", "\n\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  input[type=\"radio\"] {\n    opacity: 0;\n    position: absolute;\n    width: 0;\n    visibility: hidden;\n  }\n  .custom_definition {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 1em;\n    height: 1em;\n    border-radius: 0.5em;\n    background: ", ";\n    margin: 0.25em 1em 0.25em 0.25em;\n    border: 0.1em solid ", ";\n  }\n  input[type=\"radio\"]:checked + .custom_definition: after {\n    content: \"\";\n    width: 0.3em;\n    height: 0.3em;\n    border-radius: 0.15em;\n    background: ", ";\n  }\n  input[type=\"radio\"]:checked + .custom_definition {\n    background: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from "styled-components";
import { styled } from "@styles";
var inputStyle = css(_templateObject(), function (props) {
  return props.checked && "color: #21125E;";
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.primary;
});
export var Input = styled.div(_templateObject2(), inputStyle);
export var LabeledInput = styled.label(_templateObject3(), inputStyle);