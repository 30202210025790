import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _localeData, _localeNames;

/* eslint-disable no-restricted-syntax */
import React from "react";
import useLocalStorage from "@hooks/useLocalStorage";
import { IntlProvider } from "react-intl"; // import locale_AR from "@locale/ar.json";
// import locale_AZ from "@locale/az.json";
// import locale_BG from "@locale/bg.json";
// import locale_BN from "@locale/bn.json";
// import locale_CA from "@locale/ca.json";
// import locale_CS from "@locale/cs.json";
// import locale_DA from "@locale/da.json";
// import locale_DE from "@locale/de.json";
// import locale_EL from "@locale/el.json";
// import locale_ES from "@locale/es.json";
// import locale_ES_CO from "@locale/es_CO.json";
// import locale_ET from "@locale/et.json";
// import locale_FA from "@locale/fa.json";
// import locale_FI from "@locale/fi.json";
// import locale_FR from "@locale/fr.json";
// import locale_HI from "@locale/hi.json";
// import locale_HU from "@locale/hu.json";
// import locale_HY from "@locale/hy.json";
// import locale_ID from "@locale/id.json";
// import locale_IS from "@locale/is.json";
// import locale_IT from "@locale/it.json";
// import locale_JA from "@locale/ja.json";
// import locale_KO from "@locale/ko.json";
// import locale_LT from "@locale/lt.json";
// import locale_MN from "@locale/mn.json";
// import locale_NB from "@locale/nb.json";
// import locale_NL from "@locale/nl.json";
// import locale_PL from "@locale/pl.json";
// import locale_PT from "@locale/pt.json";
// import locale_PT_BR from "@locale/pt_BR.json";
// import locale_RO from "@locale/ro.json";
// import locale_RU from "@locale/ru.json";
// import locale_SK from "@locale/sk.json";
// import locale_SL from "@locale/sl.json";
// import locale_SQ from "@locale/sq.json";
// import locale_SR from "@locale/sr.json";
// import locale_SV from "@locale/sv.json";
// import locale_TH from "@locale/th.json";
// import locale_TR from "@locale/tr.json";
// import locale_UK from "@locale/uk.json";
// import locale_VI from "@locale/vi.json";

import locale_ZH_HANS from "@locale/zh-Hans.json";
import locale_ZH_HANT from "@locale/zh-Hant.json";
export var Locale;

(function (Locale) {
  Locale["EN"] = "en";
  Locale["ZH_HANS"] = "zh-Hans";
  Locale["ZH_HANT"] = "zh-Hant";
})(Locale || (Locale = {}));

var localeData = (_localeData = {}, _defineProperty(_localeData, Locale.EN, undefined), _defineProperty(_localeData, Locale.ZH_HANS, locale_ZH_HANS), _defineProperty(_localeData, Locale.ZH_HANT, locale_ZH_HANT), _localeData);
export var localeNames = (_localeNames = {}, _defineProperty(_localeNames, Locale.EN, "English"), _defineProperty(_localeNames, Locale.ZH_HANS, "简体中文"), _defineProperty(_localeNames, Locale.ZH_HANT, "繁體中文"), _localeNames);
var dotSeparator = "_dot_";
var sepRegExp = new RegExp(dotSeparator, "g");

function getKeyValueJson(messages) {
  if (messages) {
    var keyValueMessages = {};
    return Object.entries(messages).reduce(function (acc, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          id = _ref2[0],
          msg = _ref2[1];

      acc[id.replace(sepRegExp, ".")] = msg.string;
      return acc;
    }, keyValueMessages);
  }
}

var defaultLocale = Locale.EN;
export function doMatchingLocale(languages) {
  var localeEntries = Object.entries(Locale);
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = languages[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var perferrenceLocale = _step.value;

      for (var _i = 0, _localeEntries = localeEntries; _i < _localeEntries.length; _i++) {
        var localEntry = _localeEntries[_i];

        if (localEntry[1].toLowerCase() === perferrenceLocale.toLowerCase()) {
          return Locale[localEntry[0]];
        }
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
}
var localStorageLocale = localStorage.getItem("locale");
export var LocaleContext = React.createContext({
  localeLanguage: localStorageLocale || defaultLocale,
  setLocaleLanguage: function setLocaleLanguage() {
    return undefined;
  }
});

var LocaleProvider = function LocaleProvider(_ref3) {
  var children = _ref3.children;

  // For now locale can be set here
  // eslint-disable-next-line prefer-const
  var _useLocalStorage = useLocalStorage("locale", doMatchingLocale(navigator.languages) || defaultLocale),
      _useLocalStorage2 = _slicedToArray(_useLocalStorage, 2),
      locale = _useLocalStorage2[0],
      setLocale = _useLocalStorage2[1];

  return React.createElement(IntlProvider, {
    defaultLocale: defaultLocale,
    locale: locale,
    messages: getKeyValueJson(localeData[locale]),
    key: locale,
    onError: function onError(err) {}
  }, React.createElement(LocaleContext.Provider, {
    value: {
      localeLanguage: locale,
      setLocalLanguage: setLocale
    }
  }, children));
};

export { LocaleProvider };