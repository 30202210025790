import React from "react";
import { TaxedMoney } from "@components/containers";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Thumbnail } from "@components/molecules"; // import { Link } from "react-router-dom";

import { Button } from "src/components"; // import { TaxedMoney } from "../../@next/components/containers";

import * as S from "./styles";
export var ProductTile = function ProductTile(_ref) {
  var product = _ref.product;
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;
  return React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "product-list-item"
  }, React.createElement("div", {
    className: "product-list-item__image"
  }, React.createElement(Thumbnail, {
    source: product
  })), React.createElement("h4", {
    className: "product-list-item__title"
  }, product.name), React.createElement("p", {
    className: "product-list-item__price"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price
  })), React.createElement(Button, {
    testingContext: "addToCart",
    className: "product-list-item__button"
  }, React.createElement(FormattedMessage, commonMessages.addToCart))));
};