import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  // width: min-content;\n\n  img {\n    height: auto;\n    max-width: 180px;\n  }\n  span.quantityNum {\n    display: block;\n    position: absolute;\n    top: -0.3rem;\n    left: 50px;\n    background-color: ", ";\n    border-radius: 8px;\n    padding: 0 0.3rem;\n    min-width: 1rem;\n    height: 1rem;\n    color: ", ";\n    font-size: 0.875rem;\n    text-align: center;\n    line-height: 1rem;\n    transition: 0.3s;\n  }\n  div.item_info {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  max-height: 600px;\n  overflow-y: scroll;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  span {\n    display: inline-block;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  cursor: pointer;\n  line-height: 1rem;\n  span.payment-name {\n    width: 8rem;\n  }\n  .svg {\n    margin-left: 12px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: 700;\n  color: ", ";\n  line-height: 3rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin-left: 20px;\n  width: 100%;\n  line-height: 1rem;\n  display: flex;\n  .payment_name {\n    width: 10rem;\n  }\n  .svg {\n    margin-left: 12px;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 1rem 0 0.5rem 0;\n  border-bottom: 2px solid\n    ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  // background-color: ", ";\n  // padding: 20px;\n  // ", "\n  cursor: pointer;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 10px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Tile = styled.label(_templateObject2(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
});
export var DeliverWrapper = styled.div(_templateObject3());
export var BorderLineTitle = styled.h3(_templateObject4(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var RadioWrapper = styled.div(_templateObject5());
export var PaymentFooterTitle = styled.h3(_templateObject6(), function (props) {
  return props.theme.colors.primary;
});
export var RadioContainer = styled.label(_templateObject7());
export var WeChatForm = styled.form(_templateObject8());
export var SummaryWrapper = styled.div(_templateObject9());
export var Photo = styled.div(_templateObject10(), function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.white;
});