import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from "react";
export default function useLocalStorage(key, initialValue) {
  var _useState = useState(function () {
    var item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  }),
      _useState2 = _slicedToArray(_useState, 2),
      storedValue = _useState2[0],
      setStoredValue = _useState2[1];

  var setValue = function setValue(value) {
    var valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };

  return [storedValue, setValue];
}