import React from "react"; // import { FormattedMessage } from "react-intl";

import { Container } from "@components/templates";
import discoverus2 from "images/discoverus-2.png";
import brannerIcon from "images/lachiever-brand-story.png";
import * as S from "./styles";
export var DiscoverUs = function DiscoverUs() {
  return React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement("div", {
    className: "home-page__hero first"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("p", {
    className: "title-p title-size-line"
  }, "BRAND STORY"), React.createElement("div", {
    className: "galaxy-light"
  }), React.createElement("p", {
    className: "content-p"
  }, "In 2020, if you try to figures a country\u2019s population, it will be divided according to age X, Y, and Z generations. The post-95 generation Z (born between 1995-2009), according to the New Zealand government statistics website (Stats NZ), has exceeded the number by the end of 2020. 15%, approaching 750,000 people, while New Zealand\u2019s total population is only 5 million. This population ratio is even more alarming in China. Generation Z has a population of nearly 260 million, which will account for 18% of China\u2019s total population in the future."), React.createElement("p", {
    className: "content-p"
  }, "The new generation of Gen Z will become the mainstay of the future society, the brains that drive progress, the primary consumer of retail business, the true achievers of The Times, and perhaps even complete the progressive achievement of landing humans on Mars."), React.createElement("p", {
    className: "content-p"
  }, "l\u2019achiever, the original meaning of \u201Cthe achiever\u201D in Latin, means a person who can create the times and achieve achievements. The brand spirit comes from the image of the invincible God of War in ancient Roman mythology, and the brand logo is inspired by the evolution of the symbol of Mars.")), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    src: brannerIcon,
    alt: ""
  }))), React.createElement("div", {
    className: "home-page__hero secondary"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("p", {
    className: "title-p title-size-line"
  }, "UNIQUE SKINCARE DEMAND FOR GENERATION Z"), React.createElement("p", {
    className: "content-p"
  }, "Generation Z has its unique spiritual pursuits and consuming values. They believe that even in the next 30 years, the environment and climate change need to be updated urgently for skin protection. Say goodbye to traditional skincare products for young skin. The function of moisturizing put forward the unique skincare demand for their Z generation. Their request is to combat:"))), React.createElement("div", {
    className: "third"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    src: discoverus2,
    alt: "#"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("p", {
    className: "title title-p title-size-line"
  }, "\u201CSKINIMALISM\u201D"), React.createElement("p", {
    className: "title title-p title-size-line"
  }, "SKIN CARE ROUTINE"), React.createElement("p", {
    className: "content content-p"
  }, "\u201CLess is more\u201D as considered to be the fundamental idea of minimalism. In recent years, affected by the pandemic, environment, living habits, etc., various types of skincare problems in the new consumer group of Generation Z have frequently caused skin barrier damage, redness, inflammation, etc cases Acne will appear. -\u201CThe reason why the skin becomes red and sensitive, including excessive oil production, is a manifestation of damage to the barrier and capillaries. Therefore, it is necessary to repair the barrier epidermis and repair the damaged capillaries at the same time.\u201D"))), React.createElement("div", {
    className: "home-page__hero fourth"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("p", {
    className: "content-p"
  }, "According to online basic skin care consumption preferences and trend forecast analysis, the demand for primary skin care products such as sunscreen, cleansing, face cream, eye cream, etc. has snowballed. Natural ingredients or single functional ingredients have become the most commonly used selling points, and the basic skin care steps have been simplified and the products diversified. , The development trend of simple and efficient product ingredients. Minimalist skin care is to choose the scientifically based part after \u201Cbreaking away\u201D among the many choices, clear the skin care appeal and prescribe the active ingredients to the skin problem"))), React.createElement("div", {
    className: "fifth"
  }, React.createElement("p", {
    className: "left-text"
  }, "MINIMALIST"), React.createElement("p", {
    className: "right-text"
  }, React.createElement("span", null, "THE NUMBER OF PRODUCT USED."), React.createElement("span", null, "THE PRODUCT INGREDIENTS."))), React.createElement(Container, null, React.createElement("div", {
    className: "sixth"
  }, React.createElement("div", {
    className: "left-section"
  }), React.createElement("div", {
    className: "right-section"
  }, React.createElement("p", {
    className: "content-p"
  }, "Regression ingredients are relatively simple and effective, but the formula that can really improve the skin is the core content of minimalist skin care. First of all, the number of ingredients is not completely related to minimalist skin care. Product formulations need to think about how to retain pure and simple raw materials that are working to the skin, and get rid of the ingredients and some conceptual additives that will have negative effects in the formulation. At the same time, it does not cause too much burden. Committed to the development of harmless product concepts, including safety, sustainability and affordability. Combining the minimum safety and the natural ingredients of carefully researched synthetic materials, it has multiple functions while retaining the minimalist product ingredients.")))), React.createElement("div", {
    className: "home-page__hero seventh"
  }, React.createElement("div", {
    className: "header"
  }, React.createElement("p", {
    className: "title-p title-size-line"
  }, "TEAM STORY"), React.createElement("p", {
    className: "title-p"
  }, "Idea of 4 young male students from NZ")), React.createElement("div", {
    className: "content"
  }, React.createElement("div", null, React.createElement("p", {
    className: "content-p"
  }, "This idea comes from four young male students from New Zealand. They are all Generation Z. They love outdoor sports and e-sports, but they cannot find a skincare product that suits their skin characteristics and needs in the existing market. They have to choose the skin care products used by their father or mother as a substitute."), React.createElement("p", {
    className: "content-p"
  }, "In their words, this is not cool at all\uFF01")), React.createElement("div", null, React.createElement("p", {
    className: "content-p"
  }, "Bob's team also tackles the basic of men's skin care, creating funny trendy personalize skincare products such as Mars Sunscreen and Ocean Moisturizer.In the future, Bob's team will further expand its line of one-stop solutions for minimalist skincare."), React.createElement("p", {
    className: "content-p"
  }, "Once this idea appeared, they dared to put this idea into reality with ambition. The brand itself is the representative of Generation Z, boosting themselves achieve the future, is the real achiever of the times, proposed solving the skincare solutions used by Gen Z men and created the brand: l'achiever.")))), React.createElement("div", {
    className: "eighth"
  }, React.createElement("div", {
    className: "top-section"
  }, React.createElement("p", {
    className: "title title-p"
  }, "ECO CONCEPTION"), React.createElement("p", {
    className: "content-p"
  }, "l\u2019achiever is committed to repurposing, reusing and recycling, including using recyclable boxes made from 100% biodegradable cardboard derived from sustainable forests as well as glass bottles, aluminum tubes and printing with 100% eco ink from vegetables.")))));
};
DiscoverUs.displayName = "DiscoverUs";
export default DiscoverUs;