import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  .home-page__hero {\n    width: 100%;\n    background-image: url(", ");\n    background-repeat: no-repeat;\n  }\n  .products-featured {\n    padding-top: 0;\n  }\n  .product-header {\n    margin-left: 1.5rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: 30px;\n  line-height: 30px;\n  margin-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 4rem 13rem 4rem 13rem;\n  // font-size: ", ";\n  color: ", ";\n  border-bottom: 1px solid ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import giftingBanner from "images/gifting-banner.png";
import { styled } from "@styles";
export var DescriptionWrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.tabTitle;
}, function (props) {
  return props.theme.colors.dividerDark;
});
export var DescriptionTitle = styled.h2(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var GiftingBG = styled.div(_templateObject3(), giftingBanner);
export var DescriptionContent = styled.p(_templateObject4());