import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";

/**
 * The basic button
 */
export var Button = function Button(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "primary" : _ref$color,
      btnRef = _ref.btnRef,
      children = _ref.children,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId,
      _ref$fullWidth = _ref.fullWidth,
      fullWidth = _ref$fullWidth === void 0 ? false : _ref$fullWidth,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      props = _objectWithoutProperties(_ref, ["color", "btnRef", "children", "testingContext", "testingContextId", "fullWidth", "size"]);

  var ButtonWithTheme = color === "third" ? S.Third : color === "secondary" ? S.Secondary : S.Primary;
  return React.createElement(ButtonWithTheme, _extends({
    "data-test": testingContext,
    "data-test-id": testingContextId,
    color: color,
    fullWidth: fullWidth,
    size: size,
    ref: btnRef
  }, props), React.createElement(S.Text, {
    size: size
  }, children));
};