import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { Select, Radio, ErrorMessage } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import create from "../../../../images/cc-plus-square.svg";
import * as S from "./styles";
import "./scss/index.scss";
import { AddressFormModal } from "../AddressFormModal";
import { BillingAddress } from "./BillingAddress";
/**
 * Address form used in checkout.
 */

var addressArr = ["firstName", "lastName", "country", "city", "streetAddress1", "streetAddress2", "phone", "id", "isDefaultShippingAddress", "postalCode", "countryArea", "isDefaultBillingAddress"];

var dealWithAddr = function dealWithAddr(arr) {
  return arr === null || arr === void 0 ? void 0 : arr.map(function (i) {
    var _ref = i || {},
        address = _ref.address;

    var address1 = {}; // eslint-disable-next-line no-restricted-syntax

    for (var _i = 0, _addressArr = addressArr; _i < _addressArr.length; _i++) {
      var _i2 = _addressArr[_i];

      // eslint-disable-next-line no-restricted-syntax
      for (var _i3 = 0, _Object$entries = Object.entries(address || {}); _i3 < _Object$entries.length; _i3++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i3], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];

        if (_i2 === key) {
          address1[key] = value;
        }
      }
    }

    return address1;
  });
};

var shippingAddrOptionShow = function shippingAddrOptionShow(arr) {
  return arr === null || arr === void 0 ? void 0 : arr.map(function (i) {
    var value;
    var label = []; // eslint-disable-next-line no-restricted-syntax

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(i || {})[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _step$value = _slicedToArray(_step.value, 2),
            key = _step$value[0],
            theValue = _step$value[1];

        switch (key) {
          case "id":
            value = theValue;
            break;

          case "country":
            value = theValue === null || theValue === void 0 ? void 0 : theValue.country;
            break;

          case "isDefaultShippingAddress":
            break;

          case "isDefaultBillingAddress":
            break;

          default:
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !!theValue && label.push(theValue);
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    label = label.toString();
    return _objectSpread({
      value: value,
      label: label
    }, i);
  });
};

var isDefaultAddress = function isDefaultAddress(arr) {
  return arr.find(function (i) {
    return i.isDefaultShippingAddress === true;
  });
};

var CheckoutAddress = function CheckoutAddress(_ref2) {
  var checkoutShippingAddress = _ref2.checkoutShippingAddress,
      checkoutBillingAddress = _ref2.checkoutBillingAddress,
      billingAsShippingAddress = _ref2.billingAsShippingAddress,
      email = _ref2.email,
      selectedUserShippingAddressId = _ref2.selectedUserShippingAddressId,
      selectedUserBillingAddressId = _ref2.selectedUserBillingAddressId,
      userAddresses = _ref2.userAddresses,
      countries = _ref2.countries,
      userId = _ref2.userId,
      shippingFormId = _ref2.shippingFormId,
      shippingFormRef = _ref2.shippingFormRef,
      billingFormId = _ref2.billingFormId,
      billingFormRef = _ref2.billingFormRef,
      shippingAddressRequired = _ref2.shippingAddressRequired,
      setShippingAddress = _ref2.setShippingAddress,
      setBillingAddress = _ref2.setBillingAddress,
      setBillingAsShippingAddress = _ref2.setBillingAsShippingAddress,
      shippingErrors = _ref2.shippingErrors,
      billingErrors = _ref2.billingErrors,
      newAddressFormId = _ref2.newAddressFormId,
      shippingMethods = _ref2.shippingMethods,
      selectedShippingMethodId = _ref2.selectedShippingMethodId,
      errors = _ref2.errors,
      selectShippingMethod = _ref2.selectShippingMethod,
      shippingMethodformId = _ref2.shippingMethodformId,
      shippingMethodformRef = _ref2.shippingMethodformRef;
  var addrOptions = shippingAddrOptionShow(dealWithAddr(userAddresses));

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      defaultShippingAddr = _useState2[0],
      setDefaultShippingAddr = _useState2[1];

  var defaultCheckedShippingAddr = function defaultCheckedShippingAddr() {
    var _userAddresses$find;

    return userAddresses === null || userAddresses === void 0 ? void 0 : (_userAddresses$find = userAddresses.find(function (addr) {
      return addr.address.isDefaultShippingAddress === true;
    })) === null || _userAddresses$find === void 0 ? void 0 : _userAddresses$find.address;
  };

  useEffect(function () {
    if (selectedUserShippingAddressId) {
      setDefaultShippingAddr(addrOptions.length && (addrOptions === null || addrOptions === void 0 ? void 0 : addrOptions.filter(function (i) {
        return i.id === selectedUserShippingAddressId;
      })[0]));
    } else if (shippingAddressRequired && (addrOptions === null || addrOptions === void 0 ? void 0 : addrOptions.length) && defaultCheckedShippingAddr()) {
      var _defaultCheckedShippi;

      setDefaultShippingAddr(isDefaultAddress(addrOptions));
      setShippingAddress(defaultCheckedShippingAddr(), email, (_defaultCheckedShippi = defaultCheckedShippingAddr()) === null || _defaultCheckedShippi === void 0 ? void 0 : _defaultCheckedShippi.id);
    }
  }, []);
  var intl = useIntl();

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      createShipping = _useState4[0],
      setCreateShipping = _useState4[1];

  var AddNew = function AddNew() {
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: "create-new"
    }, React.createElement(ReactSVG, {
      onClick: function onClick() {
        return setCreateShipping(true);
      },
      path: create
    })));
  };

  return React.createElement(S.Wrapper, null, shippingAddressRequired && React.createElement(React.Fragment, null, React.createElement("section", null)), shippingAddressRequired && React.createElement("section", null, React.createElement(S.BorderLineTitle, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.deliverAddress)), React.createElement(S.AddressSelect, {
    id: shippingFormId,
    ref: shippingFormRef
  }, React.createElement("p", {
    className: "title"
  }, React.createElement(FormattedMessage, checkoutMessages.selectOrCreateDeliveryAddr)), React.createElement("div", {
    className: "address_selector"
  }, React.createElement(Select, {
    value: defaultShippingAddr,
    options: addrOptions,
    onChange: function onChange(e) {
      var address = userAddresses === null || userAddresses === void 0 ? void 0 : userAddresses.find(function (addr) {
        return addr.id === e.id;
      });
      setShippingAddress(address === null || address === void 0 ? void 0 : address.address, email, e.id);
      setDefaultShippingAddr(e);
    },
    defaultValue: defaultShippingAddr,
    placeholder: "Address Select"
  }), React.createElement(AddNew, null)), createShipping && React.createElement(AddressFormModal, {
    hideModal: function hideModal() {
      setCreateShipping(false);
    },
    submitBtnText: "Add",
    title: intl.formatMessage(checkoutMessages.addNewAddress),
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    formId: newAddressFormId,
    userId: userId
  }), React.createElement(ErrorMessage, {
    errors: shippingErrors
  })), React.createElement(React.Fragment, null)), React.createElement("section", null, React.createElement(BillingAddress, {
    checkoutAddress: checkoutBillingAddress,
    billingAsShippingAddress: billingAsShippingAddress,
    email: email,
    selectedUserAddressId: selectedUserBillingAddressId,
    userAddresses: userAddresses,
    countries: countries,
    userId: userId,
    FormId: billingFormId,
    FormRef: billingFormRef,
    setAddress: setBillingAddress,
    setBillingAsShippingAddress: setBillingAsShippingAddress,
    addressErrors: billingErrors,
    newAddressFormId: newAddressFormId,
    errors: billingErrors,
    selectedShippingAddressId: defaultShippingAddr === null || defaultShippingAddr === void 0 ? void 0 : defaultShippingAddr.id,
    addressArr: addressArr,
    dealWithAddr: dealWithAddr,
    setShippingAddress: setShippingAddress,
    shippingRequired: shippingAddressRequired
  })), React.createElement("section", null, !!(shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.length) && React.createElement(React.Fragment, null, React.createElement(S.ShippingMethodWrapper, {
    id: shippingMethodformId,
    ref: shippingMethodformRef
  }, React.createElement(S.BorderLineTitle, {
    "data-test": "checkoutPageSubtitle"
  }, intl.formatMessage(_defineProperty({
    defaultMessage: "Delivery Method",
    "id": "@next.components.organisms.CheckoutAddress.CheckoutAddress.4290494316"
  }, "id", "@next.components.organisms.CheckoutAddress.CheckoutAddress.4290494316"))), shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.map(function (_ref3) {
    var id = _ref3.id,
        name = _ref3.name;
    return React.createElement(S.RadioWrapper, {
      key: id
    }, React.createElement(Radio, {
      value: id,
      name: "shippingMethod",
      onClick: function onClick(e) {
        return selectShippingMethod ? selectShippingMethod(e.currentTarget.value) : "";
      },
      defaultChecked: selectedShippingMethodId === id
    }), React.createElement("span", null, name));
  }), React.createElement(S.DeliveryTextArea, null, React.createElement("textarea", {
    placeholder: "Delivery Instruction (if any)"
  }))), React.createElement(ErrorMessage, {
    errors: errors
  }))));
};

export { CheckoutAddress };