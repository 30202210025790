import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import * as React from "react";
import { Link } from "react-router-dom";
import { generateCategoryUrl, generateCollectionUrl, generatePageUrl, generateGiftingCategoryUrl } from "../../core/utils";
export var NavLink = function NavLink(_ref) {
  var item = _ref.item,
      props = _objectWithoutProperties(_ref, ["item"]);

  var name = item.name,
      url = item.url,
      category = item.category,
      collection = item.collection,
      page = item.page,
      parentName = item.parentName;

  var link = function link(url) {
    return React.createElement(Link, _extends({
      to: url
    }, props), name);
  };

  if (url) {
    return React.createElement("a", _extends({
      href: url
    }, props), name);
  }

  if ((name === "Gifting" || parentName === "Gifting") && category) {
    if (category) {
      return link(generateGiftingCategoryUrl(category.id, category.name));
    }
  } else if (category) {
    return link(generateCategoryUrl(category.id, category.name));
  }

  if (collection) {
    return link(generateCollectionUrl(collection.id, collection.name));
  }

  if (page) {
    return link(generatePageUrl(page.slug));
  }

  return React.createElement("span", props, name);
};