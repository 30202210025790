import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { useCart } from "@saleor/sdk";
import { Modal } from "../Modal";
import * as S from "./styles";
export var ConfirmWeToPayModal = function ConfirmWeToPayModal(_ref) {
  var hideModal = _ref.hideModal,
      submitBtnText = _ref.submitBtnText,
      cancelBtnText = _ref.cancelBtnText,
      target = _ref.target,
      title = _ref.title,
      userId = _ref.userId,
      formId = _ref.formId,
      onsubmit = _ref.onsubmit,
      errors = _ref.errors,
      props = _objectWithoutProperties(_ref, ["hideModal", "submitBtnText", "cancelBtnText", "target", "title", "userId", "formId", "onsubmit", "errors"]);

  var _React$useState = React.useState(true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      show = _React$useState2[0],
      setShow = _React$useState2[1];

  var _useCart = useCart(),
      items = _useCart.items; // const errors: any[] | undefined = [];


  return React.createElement(Modal, {
    submitButtonTestingContext: "submitAddressFormModalButton",
    testingContext: "submitAddressFormModal",
    title: title,
    hide: function hide() {
      hideModal();
      setShow(false);
    },
    formId: formId,
    disabled: false,
    show: show,
    target: target,
    submitBtnText: submitBtnText,
    cancelBtnText: cancelBtnText,
    onSubmit: onsubmit,
    errors: errors
  }, React.createElement(S.SummaryWrapper, null, items === null || items === void 0 ? void 0 : items.map(function (_ref2) {
    var _product$thumbnail, _product$thumbnail2, _variant$pricing, _variant$pricing$pric;

    var id = _ref2.id,
        quantity = _ref2.quantity,
        variant = _ref2.variant,
        totalPrice = _ref2.totalPrice;
    var product = variant.product;
    return React.createElement("div", {
      key: id
    }, React.createElement(S.Photo, null, React.createElement("img", {
      src: product === null || product === void 0 ? void 0 : (_product$thumbnail = product.thumbnail) === null || _product$thumbnail === void 0 ? void 0 : _product$thumbnail.url,
      alt: (product === null || product === void 0 ? void 0 : (_product$thumbnail2 = product.thumbnail) === null || _product$thumbnail2 === void 0 ? void 0 : _product$thumbnail2.alt) || ""
    }), React.createElement("div", {
      className: "item_info"
    }, React.createElement("span", {
      className: "title"
    }, "Name: ", product === null || product === void 0 ? void 0 : product.name), React.createElement("span", null, "SKU: ", variant === null || variant === void 0 ? void 0 : variant.sku), React.createElement("span", null, "Price: ", variant === null || variant === void 0 ? void 0 : (_variant$pricing = variant.pricing) === null || _variant$pricing === void 0 ? void 0 : (_variant$pricing$pric = _variant$pricing.price) === null || _variant$pricing$pric === void 0 ? void 0 : _variant$pricing$pric.gross.amount), React.createElement("span", null, "Quantity: ", quantity), React.createElement("span", null, "TotalPrice: ", totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount))));
  })));
};