import * as React from "react";
import { Link } from "react-router-dom";
import { Carousel, ProductListItem } from "..";
import { generateProductUrl, maybe } from "../../core/utils";
import { TypedFeaturedProductsQuery } from "./queries";
import "./scss/index.scss";

var ProductsFeatured = function ProductsFeatured(_ref) {
  var title = _ref.title;
  return React.createElement(TypedFeaturedProductsQuery, {
    displayError: false
  }, function (_ref2) {
    var data = _ref2.data;
    var products = maybe(function () {
      return data.shop.homepageCollection.products.edges;
    }, []);
    products = products.filter(function (i) {
      return i.node.category.name !== "Gifting";
    });

    if (products.length) {
      return React.createElement("div", {
        className: "products-featured"
      }, React.createElement("div", {
        className: "container"
      }, React.createElement("h3", null, title), React.createElement(Carousel, null, products.map(function (_ref3) {
        var product = _ref3.node;
        return React.createElement(Link, {
          to: generateProductUrl(product.id, product.name),
          key: product.id
        }, React.createElement(ProductListItem, {
          product: product
        }));
      }))));
    }

    return null;
  });
};

ProductsFeatured.defaultProps = {
  title: "Featured"
};
export default ProductsFeatured;