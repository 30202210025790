import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ReactSVG from "react-svg";
import arrowRightImg from "images/arrow-right.svg";
import * as S from "./styles";
var properties = {
  duration: 3000,
  autoplay: false,
  indicators: true,
  nextArrow: React.createElement("div", null, React.createElement(ReactSVG, {
    path: arrowRightImg
  }))
};
export var SlideShow = function SlideShow() {
  return React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement(Slide, properties, React.createElement("div", {
    className: "each-slide"
  }, React.createElement("div", {
    className: "text-info"
  }, React.createElement("p", null, "\u201CI've used so many expensive products in the past and l'achiever Intensive Hydrating Treatment truly is the best. It's such a beautiful product.\u201D"), React.createElement("p", {
    className: "user"
  }, "- Sean"))), React.createElement("div", {
    className: "each-slide"
  }, React.createElement("div", null, React.createElement("span", null, "Slide 2"))), React.createElement("div", {
    className: "each-slide"
  }, React.createElement("div", null, React.createElement("span", null, "Slide 3"))))));
};
SlideShow.displayName = "SlideShow";
export default SlideShow;