import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: preview;\n  width: auto;\n  max-height: 560px;\n  overflow: hidden;\n  img {\n    width: 100%;\n    object-fit: contain;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  height: 100%;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  scrollbar-width: none;\n  ::-webkit-scrollbar {\n    width: 0px;\n  }\n\n  ul {\n    position: absolute;\n    display: block;\n    padding: 0;\n    margin: 0;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  bottom: 0%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  top: 0%;\n\n  transform: rotate(180deg);\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  height: 50px;\n  width: 100%;\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(50, 50, 50, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 76px;\n  display: flex;\n  border-width: 4px;\n  border-style: solid;\n  border-color: ", ";\n  justify-content: center;\n  height: 100px;\n  overflow: hidden;\n  img {\n    width: 100%;\n    object-fit: contain;\n  }\n  // new line\n\n  video {\n    width: 100%;\n    object-fit: contain;\n  }\n\n  margin-top: 20px;\n  margin-bottom: 20px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas: \"sidebar preview\";\n  height: 100%;\n  grid-template-columns: 76px 1fr;\n  grid-column-gap: 40px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Thumbnail = styled.div(_templateObject2(), function (props) {
  return props.activeThumbnail === true ? props.theme.colors.primary : "transparent";
});
export var Button = styled.div(_templateObject3());
export var TopButton = styled(Button)(_templateObject4());
export var BottomButton = styled(Button)(_templateObject5());
export var ThumbnailsContainer = styled.div(_templateObject6());
export var ThumbnailList = styled.div(_templateObject7());
export var Preview = styled.div(_templateObject8());