import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject20() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 20px;\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = _taggedTemplateLiteral(["\n  .title {\n    display: inline-block;\n    font-weight: 800;\n    margin-bottom: 10px;\n  }\n  .radio-row {\n    display: grid;\n    grid-template-columns: 7fr 2fr;\n    line-height: 2rem;\n    span:nth-child(2) {\n      text-align: right;\n    }\n  }\n  .radio-row:not(:first-child) {\n    margin-top: 20px;\n  }\n  .radio-row:last-child {\n    margin-bottom: 30px;\n  }\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n  .title {\n    display: inline-block;\n    font-weight: 800;\n    font-family: ", ";\n    margin-bottom: 10px;\n  }\n  .radio-row {\n    display: grid;\n    line-height: 2rem;\n    grid-template-columns: 1fr 7fr 2fr;\n    span:nth-child(3) {\n      text-align: right;\n    }\n  }\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-between;\n  p {\n    font-weight: ", ";\n    font-family: ", ";\n  }\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 40px;\n  button {\n    width: 100%;\n    span {\n      font-weight: normal;\n    }\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 40px;\n  button {\n    width: 100%;\n    span {\n      font-weight: normal;\n    }\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n  div {\n    margin-bottom: 20px;\n    margin-top: 20px;\n    &:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  span {\n    display: inline-block;\n  }\n  font-weight: ", ";\n  font-family: ", ";\n  color: ", ";\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n    transform: rotate(180deg);\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n    display: unset;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    cursor: pointer;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 20px;\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  font-size: ", ";\n  text-transform: ", ";\n  font-weight: ", ";\n  font-family: ", ";\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 0;\n  padding: 0;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 30px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding: 30px 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: 0 20px 30px 20px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    top: 0%;\n    overflow-y: scroll;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    left: 0%;\n    transition: all 0.5s ease;\n    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 100%;\n  padding-top: 150px;\n  ", "\n  ", "\n  .continue-shoppingbutton.button {\n    transform: none;\n    margin: $spacer * 2 0;\n    width: 100%;\n    span {\n      transform: none;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.light;
}, media.mediumScreen(_templateObject2()), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject3());
});
export var Content = styled.div(_templateObject4());
export var ProductLine = styled.div(_templateObject5());
export var CartSummaryProductList = styled.div(_templateObject6());
export var HR = styled.hr(_templateObject7(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.div(_templateObject8(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.message.titleTransform;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.boldFontFamily;
}, media.mediumScreen(_templateObject9(), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var ArrowUp = styled.div(_templateObject10(), media.mediumScreen(_templateObject11()), function (props) {
  return props.mobileCartOpened && media.mediumScreen(_templateObject12());
});
export var CostLine = styled.div(_templateObject13(), function (props) {
  return props.last ? props.theme.typography.boldFontWeight : "normal";
}, function (props) {
  return props.last ? props.theme.typography.boldFontFamily : "inherit";
}, function (props) {
  return props.last ? props.theme.colors.primary : "normal";
});
export var Costs = styled.div(_templateObject14());
export var ProceedButton = styled.div(_templateObject15());
export var NeutualButton = styled.div(_templateObject16());
export var SubTotal = styled.div(_templateObject17(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.boldFontFamily;
});
export var DeliveryMethod = styled.div(_templateObject18(), function (props) {
  return props.theme.typography.boldFontFamily;
});
export var CheckOutDeleveryMethod = styled.div(_templateObject19());
export var DiscountCode = styled.div(_templateObject20());