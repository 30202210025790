import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "search"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Out of stock"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Low stock"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "No items available"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Not available for purchase"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Will become available for purchase on {date} at {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "You might like"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Please choose payment method."
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "Please provide email address."
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Account"
  },
  rewards: {
    "id": "intl.commonMessages.rewards",
    defaultMessage: "My Rewards"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "My Account"
  },
  accountSetting: {
    "id": "intl.commonMessages.accountSetting",
    defaultMessage: "Account Setting"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Order history"
  },
  myOrder: {
    "id": "intl.commonMessages.myOrder",
    defaultMessage: "My Orders"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "Address book"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Log Out"
  },
  SingOut: {
    "id": "intl.commonMessages.SingOut",
    defaultMessage: "Sign Out"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Last Name"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Password"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Quantity"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "SKU"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maximum quantity is {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Quantity"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "Subtotal"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "Shipping"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "Promo code"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "Total"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "Total Price"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Checkout"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "Email Address"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "Email"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Loading"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Products"
  },
  product: {
    "id": "intl.commonMessages.product",
    defaultMessage: "Product"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Price"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Variant"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Phone"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "Phone number: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Save"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Add"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "FILTERS"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "CLEAR FILTERS"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Cancel"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Home"
  },
  allproduct: {
    "id": "intl.commonMessages.allproduct",
    defaultMessage: "All Product"
  },
  addToCart: {
    "id": "intl.commonMessages.addToCart",
    defaultMessage: "ADD TO CART"
  },
  signInOrReg: {
    "id": "intl.commonMessages.signInOrReg",
    defaultMessage: "Sign In / Register"
  },
  discount: {
    "id": "intl.commonMessages.discount",
    defaultMessage: "Discount"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Address"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Shipping"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Payment"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Review"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Continue to Shipping"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Continue to Payment"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Continue to Review"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Place order"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Add new address"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "SHIPPING METHOD"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "Billing Address"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "PAYMENT METHOD"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "REVIEW ORDER"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Shipping Address"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "CONTINUE SHOPPING"
  },
  secureCheckout: {
    "id": "intl.checkoutMessages.secureCheckout",
    defaultMessage: "Secure Checkout"
  },
  hey: {
    "id": "intl.checkoutMessages.hey",
    defaultMessage: "Hey {firstName} {lastName},"
  },
  deliverAddress: {
    "id": "intl.checkoutMessages.deliverAddress",
    defaultMessage: "Delivery Address"
  },
  selectOrCreateDeliveryAddr: {
    "id": "intl.checkoutMessages.selectOrCreateDeliveryAddr",
    defaultMessage: "Select from a saved delivery address or enter a new delivery address."
  },
  selectOrCreateBillingAddr: {
    "id": "intl.checkoutMessages.selectOrCreateBillingAddr",
    defaultMessage: "Select from a saved billing address or enter a new billing address."
  },
  chooseNoPaymentMethodError: {
    "id": "intl.checkoutMessages.chooseNoPaymentMethodError",
    defaultMessage: "Choose a method of payment first please."
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Clear..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Price Low-High"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Price High-Low"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Name Increasing"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Name Decreasing"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Last updated Ascending"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Last updated Descending"
  }
});
export var giftingListHeaderComminMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.giftingListHeaderComminMsg.sortOptionsClear",
    defaultMessage: "Most Popular"
  },
  sortOptionsPrice: {
    "id": "intl.giftingListHeaderComminMsg.sortOptionsPrice",
    defaultMessage: "Price Low-High"
  },
  sortOptionsPriceDsc: {
    "id": "intl.giftingListHeaderComminMsg.sortOptionsPriceDsc",
    defaultMessage: "Price High-Low"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.giftingListHeaderComminMsg.sortOptionsUpdatedAt",
    defaultMessage: "Last updated Ascending"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.giftingListHeaderComminMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Last updated Descending"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Not charged"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    defaultMessage: "Partially charged"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Fully charged"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    defaultMessage: "Partially refunded"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Fully refunded"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Draft"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "Unfulfilled"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: "Partially fulfilled"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "Fulfilled"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Canceled"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}