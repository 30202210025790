import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react"; // import { useHistory, useLocation } from "react-router-dom";

import { DiscoverUs } from "@components/organisms"; // import { BASE_URL } from "@temp/core/config";
// import { generateGuestOrderDetailsUrl } from "@utils/core";

var DiscoverUsPage = function DiscoverUsPage(_ref) {
  _objectDestructuringEmpty(_ref);

  // const location = useLocation();
  // const history = useHistory();
  // const { token, orderNumber } = location.state;
  return React.createElement(DiscoverUs, null);
};

export { DiscoverUsPage };