import "./scss/index.scss";
import * as React from "react";
import Nav from "./Nav";

var Footer = function Footer() {
  return React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement(Nav, null), React.createElement("div", {
    className: "footer__copyright container"
  }, "\xA9 2021 LACHIEVER LIMITED. ALL RIGHTS RESERVED."));
};

export default Footer;