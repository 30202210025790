import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: auto;\n  max-width: 100%;\n\n  > img {\n    width: auto;\n    height: auto;\n    max-width: 100%;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-transform: ", ";\n  font-weight: normal;\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  //   padding: 1.8rem;\n  // "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .product-list-item {\n    text-align: center;\n\n    &__title {\n      text-transform: capitalize;\n      font-weight: ", ";\n      margin-top: ", ";\n      text-align: center;\n      min-height: 3rem;\n      font-family: ", ";\n    }\n\n    &__undiscounted_price {\n      text-decoration: line-through;\n      color: $gray;\n      font-size: $small-font-size;\n      font-weight: regular;\n    }\n\n    &__category {\n      color: $product-item-category-color;\n      text-align: left;\n    }\n\n    &__price {\n      font-weight: 500;\n      margin: $product-item-price-margin;\n      text-align: center;\n    }\n\n    &__button.button {\n      background-color: ", ";\n      border: solid 1px $turquoise;\n      color: $turquoise;\n      transform: none;\n      margin-top: $spacer;\n      box-shadow: none;\n      span {\n        color: $turquoise;\n        transform: none; \n        font-weight: 400;\n      }\n    }\n\n    &__button.button:hover {\n      background-color: $turquoise;\n      border: solid 1px $turquoise;\n      color: ", ";\n      transform: none;\n      span {\n        color: ", ";\n        transform: none; \n      }\n    }\n\n    &__image {\n      background-color: ", ";\n      border: solid 1px #f1f5f5;\n      display: flex;\n      height: 300px;\n      justify-content: center;\n\n      img {\n        object-fit: contain;\n        max-width: 100%;\n      }\n\n      @media (max-width: $small-screen) {\n        height: 210px;\n      }\n    }\n  }\n  // background: ", ";\n  // padding: 2.5rem;\n  // text-align: center;\n  // max-height: 30rem;\n  // transition: 0.3s;\n\n  // :hover {\n  //   background-color: ", ";\n  // }\n\n  // ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.typography.boldFontFamily;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.hoverLightBackground;
}, media.largeScreen(_templateObject3()));
export var Title = styled.h4(_templateObject4(), function (props) {
  return props.theme.message.titleTransform;
}, textProps);
export var Price = styled.p(_templateObject5(), textProps);
export var Image = styled.div(_templateObject6());