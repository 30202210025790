import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 0 50px 0;\n  h4 {\n    font-family: \"DIN2014-Bold\";\n    padding: 20px 0;\n    width: 100%;\n    font-size: ", ";\n    font-weight: ", ";\n    border-bottom: 1px solid\n      ", ";\n  }\n  p {\n    font-size: 16px;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    position: fixed;\n    bottom: 0;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: checkoutSummary;\n  height: 100%;\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-row-gap: 20px;\n    width: 100%;\n    margin-top: 20px;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin: 40px 0 100px 0;\n  // display: grid;\n  // grid-template-columns: 1fr 1fr;\n  // grid-column-gap: 30px;\n  display: flex;\n  align-items: center;\n  button {\n    margin-right: 3rem;\n    padding: 0.9rem 1.7rem;\n    span {\n      font-family: \"DIN2014-Regular\";\n      font-weight: 200;\n    }\n  }\n  a {\n    color: ", ";\n  }\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: paragraph;\n  font-size: ", ";\n  margin: 14px 0 0 0;\n  line-height: 170%;\n\n  span {\n    font-weight: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: header;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  line-height: 110%;\n  padding: 20px 0;\n  div {\n    display: flex;\n    flex-direction: column;\n    margin: 0 0 0 1.5rem;\n    span {\n      font-weight: ", ";\n      &:first-child {\n        font-size: 40px;\n        color: ", ";\n        line-height: 3rem;\n      }\n      &:nth-child(2) {\n        font-size: 20px;\n        color: ", ";\n        line-height: 2rem;\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"header\"\n      \"hFTitle\"\n      \"paragraph\"\n      \"paymentGateways\"\n      \"button\";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"header checkoutSummary\"\n    \"hFTitle checkoutSummary\"\n    \"paragraph checkoutSummary\"\n    \"paymentGateways checkoutSummary\"\n    \"button checkoutSummary\";\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), media.mediumScreen(_templateObject2()));
export var ThankYouHeader = styled.div(_templateObject3(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.primaryLight;
}, function (props) {
  return props.theme.colors.inputColor;
}, media.smallScreen(_templateObject4(), function (props) {
  return props.theme.typography.h1FontSize;
})); // border-bottom: 1px solid
//   ${props => props.theme.colors.baseFontColorTransparent};

export var Paragraph = styled.div(_templateObject5(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Buttons = styled.div(_templateObject6(), function (props) {
  return props.theme.colors.primary;
}, media.smallScreen(_templateObject7()));
export var CheckoutSummary = styled.div(_templateObject8(), media.mediumScreen(_templateObject9()));
export var HFTitleWrapper = styled.div(_templateObject10(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
});