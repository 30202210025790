import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Icon, IconButton } from "@components/atoms";
import { CachedImage, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { generateProductUrl } from "../../../../core/utils";
import * as S from "./styles";

var QuantityLeftButtons = function QuantityLeftButtons(subtract, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: subtract,
    "data-test": "subtractButton"
  }, React.createElement(Icon, {
    size: 12,
    name: "horizontal_line",
    color: "#bbbbbb"
  })));
};

var QuantityRightButtons = function QuantityRightButtons(add, index) {
  return React.createElement(S.QuantityButtons, {
    "data-test": "quantityControls"
  }, React.createElement("div", {
    onClick: add,
    "data-test": "increaseButton"
  }, React.createElement(Icon, {
    size: 12,
    name: "plus",
    color: "#bbbbbb"
  })));
};
/**
 * Product row displayed on cart page
 */


export var CartRow = function CartRow(_ref) {
  var index = _ref.index,
      totalPrice = _ref.totalPrice,
      unitPrice = _ref.unitPrice,
      name = _ref.name,
      sku = _ref.sku,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      thumbnail = _ref.thumbnail,
      _ref$attributes = _ref.attributes,
      attributes = _ref$attributes === void 0 ? [] : _ref$attributes,
      onRemove = _ref.onRemove,
      id = _ref.id;

  var _useState = useState(quantity.toString()),
      _useState2 = _slicedToArray(_useState, 2),
      tempQuantity = _useState2[0],
      setTempQuantity = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isTooMuch = _useState4[0],
      setIsTooMuch = _useState4[1];

  var intl = useIntl();

  var handleBlurQuantityInput = function handleBlurQuantityInput() {
    var newQuantity = parseInt(tempQuantity, 10);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    var newTempQuantity = newQuantity.toString();

    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);
  };

  useEffect(function () {
    setTempQuantity(quantity.toString());
  }, [quantity]);
  var add = React.useCallback(function () {
    return quantity < maxQuantity && onQuantityChange(quantity + 1);
  }, [quantity]);
  var subtract = React.useCallback(function () {
    return quantity > 1 && onQuantityChange(quantity - 1);
  }, [quantity]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    var newQuantity = parseInt(evt.target.value, 10);
    setTempQuantity(evt.target.value);
    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var quantityErrors = isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  var productUrl = generateProductUrl(id, name);
  return React.createElement(S.Wrapper, {
    "data-test": "cartRow",
    "data-test-id": sku
  }, React.createElement(S.Photo, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(CachedImage, _extends({
    "data-test": "itemImage"
  }, thumbnail)))), React.createElement(S.Description, null, React.createElement(Link, {
    to: productUrl
  }, React.createElement(S.Name, {
    "data-test": "itemName"
  }, name))), React.createElement(S.UnitPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.price), ":")), React.createElement("p", {
    "data-test": "unitPrice"
  }, unitPrice)), React.createElement(S.Quantity, null, React.createElement(TextField, {
    name: "quantity",
    label: intl.formatMessage(commonMessages.qty),
    value: tempQuantity,
    onBlur: handleBlurQuantityInput,
    onChange: handleQuantityChange,
    contentLeft: QuantityLeftButtons(subtract, index),
    contentRight: QuantityRightButtons(add, index),
    errors: quantityErrors,
    className: "fix_width"
  })), React.createElement(S.Trash, null, React.createElement(IconButton, {
    testingContext: "removeButton",
    testingContextId: sku,
    size: 22,
    name: "trash",
    color: "#bbbbbb",
    onClick: onRemove
  })), React.createElement(S.TotalPrice, null, React.createElement(S.PriceLabel, null, React.createElement(S.LightFont, null, React.createElement(FormattedMessage, commonMessages.totalPrice), ":")), React.createElement("p", {
    "data-test": "totalPrice"
  }, totalPrice)));
};