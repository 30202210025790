import React from "react"; // import { Container } from "../Container";

import * as S from "./styles";

/**
 * Cart template for cart page with list of products added by user.
 */
var Cart = function Cart(_ref) {
  var breadcrumbs = _ref.breadcrumbs,
      title = _ref.title,
      cartHeader = _ref.cartHeader,
      cartFooter = _ref.cartFooter,
      cart = _ref.cart,
      button = _ref.button;
  return React.createElement(S.Wrapper, null, React.createElement(S.Breadcrumbs, null, breadcrumbs), React.createElement(S.Title, null, title), React.createElement(S.CartHeader, null, cartHeader), React.createElement(S.Cart, null, cart));
};

export { Cart };