import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { useAuth } from "@saleor/sdk";
import { ServiceWorkerContext } from "@components/containers";

var Notifications = function Notifications() {
  var alert = useAlert();
  var intl = useIntl();

  var _React$useContext = React.useContext(ServiceWorkerContext),
      updateAvailable = _React$useContext.updateAvailable;

  React.useEffect(function () {
    if (updateAvailable) {
      alert.show({
        actionText: intl.formatMessage({
          defaultMessage: "Refresh",
          "id": "app.Notifications.1308518649"
        }),
        content: intl.formatMessage({
          defaultMessage: "To update the application to the latest version, please refresh the page!",
          "id": "app.Notifications.1053546789"
        }),
        title: intl.formatMessage({
          defaultMessage: "New version is available!",
          "id": "app.Notifications.795565"
        })
      }, {
        onClose: function onClose() {
          location.reload();
        },
        timeout: 0,
        type: "success"
      });
    }
  }, [updateAvailable]);

  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated;

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      prevAuthenticated = _React$useState2[0],
      setPrevAuthenticated = _React$useState2[1];

  React.useEffect(function () {
    if (prevAuthenticated !== undefined && authenticated !== undefined) {
      if (!prevAuthenticated && authenticated) {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "You are now logged in",
            "id": "app.Notifications.1917823234"
          })
        }, {
          type: "success"
        });
      } else if (prevAuthenticated && !authenticated) {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "You are now logged out",
            "id": "app.Notifications.2378877294"
          })
        }, {
          type: "success"
        });
      }

      setPrevAuthenticated(authenticated);
    } else if (authenticated !== undefined) {
      setPrevAuthenticated(authenticated);
    }
  }, [authenticated]);
  return React.createElement("div", {
    className: "notice-section",
    style: {
      display: "none"
    }
  }, "Notice area (can be hidden when not needed)");
};

export default Notifications;