import React from "react";
import { FormattedMessage } from "react-intl";
import { LoginForm } from ".."; // import ForgottenPassword from "../OverlayManager/Login/ForgottenPassword";

var SignInForm = function SignInForm(_ref) {
  var onForgottenPasswordClick = _ref.onForgottenPasswordClick;
  return React.createElement(React.Fragment, null, React.createElement("h2", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.SignInForm.1173110163",
    defaultMessage: "Sign In"
  })), React.createElement(LoginForm, null));
};

export default SignInForm;