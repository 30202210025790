import React from "react"; // import { Carousel } from "src/components";

import { SlideShow } from "@components/organisms";
import { Container } from "@components/templates";
import * as S from "./styles";
export var UsersReview = function UsersReview() {
  return React.createElement(S.OuterWrapper, null, React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "left-section"
  }), React.createElement("div", {
    className: "right-section"
  }, React.createElement(SlideShow, null)))));
};
UsersReview.displayName = "UsersReview";
export default UsersReview;