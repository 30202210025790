import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .static-img {\n    width: 100%;\n    min-height: 400px;\n    overflow: hidden;\n    background-image: url(", ");\n    background-size: cover;\n    background-repeat: no-repeat;\n    position: relative;\n  }\n  .img-info {\n    width: 300px;\n    position: absolute;\n    top: 35%;\n    right: 3%;\n    font-size: 15px;\n    color: ", ";\n    text-align: justify;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 80px;\n  min-height: 400px;\n  max-height: 400px;\n  .video-container {\n    display: grid;\n    grid-template-columns: 60% 40%;\n    padding: 0 1rem;\n  }\n  .left-section,\n  .right-section {\n    min-height: 100%;\n    video {\n      width: 100%;\n      height: 400px;\n      object-fit: contain;\n    }\n  }\n  .right-section {\n    padding-left: 80px;\n    .video-title {\n      display: inline-block;\n      padding-bottom: 20px;\n      margin-top: 80px;\n      font-size: 1.8rem;\n      font-weight: 900;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import video from "images/home-1.png";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var ImageWrapper = styled.div(_templateObject2(), video, function (props) {
  return props.theme.colors.primary;
});
export var OuterWrapper = styled.div(_templateObject3());