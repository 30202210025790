import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl"; // import { commonMessages } from "@temp/intl";

import * as S from "./styles"; // import { ProductsFeatured } from "../../components";

import { ProductListHeader } from "../../@next/components/molecules";
import { ProductList } from "../../@next/components/organisms";
import { maybe } from "../../core/utils";

var Page = function Page(_ref) {
  var activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      title = _ref.title,
      discription = _ref.discription,
      category = _ref.category,
      displayLoader = _ref.displayLoader,
      hasNextPage = _ref.hasNextPage,
      clearFilters = _ref.clearFilters,
      onLoadMore = _ref.onLoadMore,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  }); // const hasProducts = canDisplayProducts && !!products.totalCount;
  // const intl = useIntl();

  var getAttribute = function getAttribute(attributeSlug, valueSlug) {
    return {
      attributeSlug: attributeSlug,
      valueName: attributes.find(function (_ref2) {
        var slug = _ref2.slug;
        return attributeSlug === slug;
      }).values.find(function (_ref3) {
        var slug = _ref3.slug;
        return valueSlug === slug;
      }).name,
      valueSlug: valueSlug
    };
  };

  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(key, valueSlug);
    }));
  }, []);
  return React.createElement("div", {
    className: "category"
  }, React.createElement(S.GiftingBG, null, React.createElement("div", {
    className: "home-page__hero"
  }, React.createElement("div", {
    className: "home-page__hero-container"
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.GiftingCategory.Page.2120931672",
    defaultMessage: "GIFTS FOR HIM"
  })))), React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title sub-custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.GiftingCategory.Page.2907905366",
    defaultMessage: "(To be replaced with seasonal promotional & gifting ideas)"
  })))))))), React.createElement("div", {
    className: "container"
  }, React.createElement(ProductListHeader, {
    activeSortOption: activeSortOption,
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange,
    leftTitle: "GIFTS & SETS"
  }), canDisplayProducts && React.createElement("div", {
    className: "product-list"
  }, React.createElement(ProductList, {
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    canLoadMore: hasNextPage,
    loading: displayLoader,
    onLoadMore: onLoadMore
  }))));
};

export default Page;