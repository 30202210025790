import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .home-page__hero {\n    background-image: url(", ");\n    background-repeat: no-repeat;\n  }\n  .content-section:nth-child(odd) {\n    border-top: solid 2px #bbb;\n    padding: 80px 0;\n    display: grid;\n    grid-template-columns: 60% 40%;\n    .left-section img {\n      width: 100%;\n    }\n    .right-section {\n      margin: 0 30px;\n      align-self: center;\n      h2 {\n        font-size: 30px;\n        font-weight: 900;\n        margin-bottom: 20px;\n        line-height: 1.2;\n      }\n      p {\n        font-size: 16px;\n        font-weight: 500;\n        text-align: justify;\n        min-height: 100px;\n      }\n      .border {\n        width: 100px;\n        height: 5px;\n        margin-top: 50px;\n        background-color: ", ";\n      }\n    }\n  }\n  .content-section:nth-child(even) {\n    padding: 80px 0;\n    border-top: solid 2px #bbb;\n    display: grid;\n    grid-template-columns: 40% 60%;\n    .right-section img {\n      width: 100%;\n    }\n    .left-section {\n      margin: 0 30px 0 0;\n      align-self: center;\n      h2 {\n        font-size: 30px;\n        font-weight: 900;\n        margin-bottom: 20px;\n        line-height: 1.2;\n      }\n      p {\n        font-size: 16px;\n        font-weight: 500;\n        text-align: justify;\n      }\n      .border {\n        width: 100px;\n        height: 5px;\n        margin-top: 50px;\n        background-color: ", ";\n      }\n    }\n  }\n  .content-container:nth-child(1) .content-section:nth-child(1) {\n    border-top: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import ingredientsBanner from "images/Ingredients-banner.png";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), ingredientsBanner, function (props) {
  return props.theme.colors.primaryLight;
}, function (props) {
  return props.theme.colors.primaryLight;
});