import React from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "@components/templates";
import ingredients1 from "images/ingredients.png";
import ingredients2 from "images/ingredients-2.png";
import ingredients3 from "images/ingredients-3.png";
import ingredients4 from "images/ingredients-4.png";
import ingredients5 from "images/ingredients-5.png";
import ingredients6 from "images/ingredients-6.png";
import ingredients7 from "images/ingredients-7.png";
import ingredients8 from "images/ingredients-8.png";
import ingredients9 from "images/ingredients-9.png";
import * as S from "./styles";
export var Ingredients = function Ingredients() {
  return React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement("div", {
    className: "home-page__hero"
  }, React.createElement("div", {
    className: "home-page__hero-container"
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Ingredients.Ingredients.794568209",
    defaultMessage: "OUR INGREDIENTS"
  })))), React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title sub-custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Ingredients.Ingredients.3503574862",
    defaultMessage: "Combining the power of nature and science, l\u2019achiever men skincare products selected\r\nNew Zealand's purely natural ingredients, corporate with biotechnology extraction\r\ntechnology with a local third-party laboratory and manufacturer in New Zealand."
  })))))))), React.createElement(Container, null, React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients1
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "NZ MANUKA HONEY"), React.createElement("p", null, "Manukau Honey is a natural humectant. It is extremely rich in proteins, vitamins and minerals and has the outstanding ability to nourish soothe and heal sensitive skin. Due to Manuka honey s anti-inflammatory properties, it can help to heal inflamed acne and can help to relieve redness and other skin conditions such as eczema in a completely natural way."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "NZ SAUVIGNON BLANC GRAPE SEEDS EXTRACT"), React.createElement("p", null, "Advanced protective for sun and after-sun care. Prevent environmental damage with harmful rays and blue screen light, and important in defending against free radicals. Super powerful antioxidant Complex and anti-aging protector delivers skin nourishing nutrients to help maintain a healthy brightened appearance.", " "), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients2
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients3
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "NZ HERBAL POHUTUKAWA EXTRACT"), React.createElement("p", null, "Pohutakawa is a native New Zealand tree, and it was as used in traditional Maori herbal medicine for wounds and abrasions.", React.createElement("br", null), " The bark contains Ellagic Acid which has antioxidant and anti-inflammatory properties. Pohutukawa extract helps calm and soothe red, irritated skin whilst deeply moisturizing it."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "NZ CALENDULA"), React.createElement("p", null, "Acting to visibly even skin tone and boost radiance, while gently calming any redness or irritation. Upon application, this unique moisturiser provides a burst of 24-hour hydration for fresh, glowing skin."), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients4
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients5
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "NZ CERTIFICATED ORGANIC OLIVE OIL"), React.createElement("p", null, "Extra Virgin Olive oil is a premium grade of olive oil taken from the first pressing of the fruit.", React.createElement("br", null), "Olive oils are high in Oleic acid \u2013 in fact, the fatty acid is named after the olive, a monounsaturated fatty acid. This makes it fantastic for moisturising and restoring the skin."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "FOOD GRADE SOYBEAN LECITHIN"), React.createElement("p", null, "This fat substitute helps two or more ingredients mix or emulsify that typically donnot want to be combined. For example, yeast with sugar, flour and water or cocoa powder and butter or oil and vinegar for salad dressings."), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients6
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients7
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "ALPINE WILLOW HERB EXTRACT"), React.createElement("p", null, "This beautiful mountain flower helps reduce inflammation and calms skin impurities. It also controls sebum production, reducing pore size, and minimising blackheads. We chose this extract to help battle both irritation and balance oil production. It is a versatile ingredient suitable for sensitive, combination, and oily skin types."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "NZ BIOACTIVE TOTAROL"), React.createElement("p", null, "Bioactive Totarol\u2122, and it\u2019s a 100% natural organic ingredient. A uniquely New Zealand product that has antibacterial, anti-inflammatory and antioxidant properties. Totarol\u2122 has the ability to replace current antibiotics, fight superbugs and has proven to prohibit resistance build-up."), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients8
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients9
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "MARINE COLLAGEN NANOTECHNOLOGY"), React.createElement("p", null, "Like other sources of collagen, marine collagen may help delay some signs of aging, like wrinkles, joint issues, and weakness."), React.createElement("div", {
    className: "border"
  }))))));
};
Ingredients.displayName = "Ingredients";
export default Ingredients;