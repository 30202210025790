import React from "react";
import { FormattedMessage } from "react-intl";

var ForgottenPassword = function ForgottenPassword(_ref) {
  var onClick = _ref.onClick;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "login__content__password-reminder"
  }, React.createElement("p", null, React.createElement("span", {
    className: "u-link",
    onClick: onClick,
    "data-test": "accountOverlayForgottenPasswordLink"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Login.ForgottenPassword.145155449",
    defaultMessage: "Forgot Password?"
  })))));
};

export default ForgottenPassword;